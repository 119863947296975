import { type Component, Show, ErrorBoundary } from "solid-js";
import { type AccessorWithLatest, createAsync } from "@solidjs/router";
import { type Product } from "~/services/roma-api/products/types";
import { getProductPricing } from "~/services/products";
import { BOX } from "~/utils/products";
import { useSessionContext } from "~/utils/contexts";

type BoxQtyProps = {
  sku: string;
  product: AccessorWithLatest<Product | undefined>;
};

export const BoxQty: Component<BoxQtyProps> = (props) => {
  const {isPartner} = useSessionContext();
  const pricing = createAsync(() => getProductPricing(props.sku));

  return (
    <>
      <Show when={isPartner()}>
        <ErrorBoundary
              fallback={(error, reset) => {
                // TODO - Sentry
                return (
                  <div class="bg-red-100 rounded-md p-3 text-sm">
                    <p>
                      Error retrieving pricing information. Please try again
                      later, we apologize for the inconvenience.
                    </p>
                  </div>
                );
              }}
            >
          <Show
            when={
              props.product()?.AvailableAs.includes(BOX) && pricing()?.Pricing[BOX]
            }
          >
            <div class="grid grid-cols-product-headers items-center text-sm  ">
              <p class="font-bold">Box Qty</p>
              <div class=" font-medium text-sm tracking-wider">
                <p>{pricing()?.Pricing[BOX]?.Quantity} ft. </p>
              </div>
            </div>
          </Show>
        </ErrorBoundary>
      </Show>
    </>
  );
};
